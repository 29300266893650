let pswpElement = null;

const gallerySelector = '.pswp-gallery';
const itemSelector = '.pswp-gallery__item';

// const scrollMarginElementIDTop = 'nav_header';
// const navbarHidesOnScrollDown = true;

// const scrollMarginElementIDBottom = 'stickycontent';

// const ScrollMargin = 16;
// const scrollMarginTopElementMargin = document.getElementById(scrollMarginElementIDTop).getBoundingClientRect().height;
// const scrollMarginBottomElementMargin = document.getElementById(scrollMarginElementIDBottom).getBoundingClientRect().height;
// const scrollMarginTop = ScrollMargin;
// const scrollMarginBottom = scrollMarginBottomElementMargin + ScrollMargin;

let initialLoad = true;
let isOpenFully = false;

let fullscreenOnOpen = false;

let scrollAnimationFrame;
let scrollElement = null;

let isSwitchingGalleries = false;
let newSwitchingGalleryIndex = 0;
let newSwitchingItemIndex = 0;

// ============================================================================
// Debug
// ============================================================================

const debugLogConfig = {
  Event:               { enabled: true, color: 'color: #9fbbe0' }, // blue (50% white)
  Content:             { enabled: false, color: 'color: #ffbf80' }, // orange (50% white)
  PointerGesture:      { enabled: false, color: 'color: #96d896' }, // green (50% white)
  EventDocumentWindow: { enabled: false, color: 'color: #eb9393' }, // red (50% white)
  Scroll:              { enabled: true, color: 'color: #c8a8e0' }, // purple (50% white)
  Caption:             { enabled: false, color: 'color: #c5aaa2' }, // brown (50% white)
  Fullscreen:          { enabled: false, color: 'color: #f1b9d6' }, // pink (50% white)
  Share:               { enabled: false, color: 'color: #8bf1f1' }, // cyan (50% white)
  URL:                 { enabled: false, color: 'color: #d6d859' }, // yellow-green (50% white)
  Blur:                { enabled: false, color: 'color: #9fbf9f' }, // greenish (50% white)
};

function debugLog(tag, ...msgs) {
  const config = debugLogConfig[tag];
  if (config && config.enabled) {
    console.log(`%c[${tag}]`, config.color, ...msgs);
  }
}

// ============================================================================
// Construct
// ============================================================================

const lightbox = new PhotoSwipeLightbox({
  gallery: gallerySelector,
  children: itemSelector,
  pswpModule: PhotoSwipe
});

function isValidLightbox() {
  return lightbox && lightbox.pswp;
}

// ============================================================================
// Open
// ============================================================================

function clamp(value, min, max) {
  return Math.max(min, Math.min(max, value));
}

function openGalleryItemIndexes(galleryIndex, itemIndex) {
  debugLog('URL', 'openGalleryItemIndexes, galleryIndex:', galleryIndex, ', itemIndex:', itemIndex);

  const galleryElements = document.querySelectorAll(gallerySelector);

  const galleryElement = galleryElements[galleryIndex];

  lightbox.loadAndOpen(itemIndex, { gallery: galleryElement });
}

// ============================================================================
// URL
// ============================================================================

// Function to update the URL with the current gallery and item
function updateURL(galleryIndex, itemIndex) {
  debugLog('URL', 'updateURL, galleryIndex:', galleryIndex, ', itemIndex:', itemIndex);

  const newURL = `${window.location.origin}${window.location.pathname}#&gallery=${galleryIndex}&item=${itemIndex}`;
  window.history.pushState({ path: newURL }, '', newURL);
}

function updateURLWithoutGalleryItem() {
  debugLog('URL', 'updateURLWithoutGalleryItem');

  const newURL = window.location.origin + window.location.pathname;
  window.history.pushState({ path: newURL }, '', newURL);
}

// Function to parse URL parameters with defaults
function photoswipeParseHash() {
  debugLog('URL', 'photoswipeParseHash');

  const hash = window.location.hash.substring(1);
  const params = {};

  if (hash.length < 5) {
    return { gallery: false, item: false };
  }

  const vars = hash.split('&');
  for (let i = 0; i < vars.length; i++) {
    if (!vars[i]) {
      continue;
    }
    const pair = vars[i].split('=');
    if (pair.length < 2) {
      continue;
    }
    params[pair[0]] = pair[1];
  }

  if ('gallery' in params || 'item' in params) {
    params.gallery = parseInt(params.gallery, 10) || 1;
    params.item = parseInt(params.item, 10) || 1;

    return params;
  } else {
    return { gallery: false, item: false };
  }
}

// Parse URL and open gallery if it contains #&item=3&gallery=1
function openGalleryFromHash() {
  debugLog('URL', 'openGalleryFromHash');
  
  const hashData = photoswipeParseHash();

  if (!(hashData.gallery && hashData.item)) {
    initialLoad = false;
    return;
  }

  const galleryElements = document.querySelectorAll(gallerySelector);

  // Default to gallery 1, item 1 if not provided
  let galleryIndex = (hashData.gallery && hashData.gallery - 1) || 0;
  let itemIndex = (hashData.item && hashData.item - 1) || 0;

  const numberOfGalleries = galleryElements.length;

  // Clamp gallery index within range
  galleryIndex = clamp(galleryIndex, 0, numberOfGalleries - 1);

  const galleryElement = galleryElements[galleryIndex];
  const itemElements = galleryElement.querySelectorAll(itemSelector);
  const numberOfItems = itemElements.length;

  // Clamp item index within range
  itemIndex = clamp(itemIndex, 0, numberOfItems - 1);

  if (lightbox.pswp) {
    const currentGalleryElement = lightbox.pswp.options.dataSource.gallery;
    const currentItemIndex = lightbox.pswp.currIndex;

    if (currentGalleryElement !== galleryElement) {
      // Close current gallery and open the new one
      isSwitchingGalleries = true;
      newSwitchingGalleryIndex = galleryIndex;
      newSwitchingItemIndex = itemIndex;

      lightbox.pswp.close();
    } else if (currentItemIndex !== itemIndex) {
      // Only navigate to a different item within the same gallery
      console.log(`goTo: ${itemIndex}`);
      lightbox.pswp.goTo(itemIndex);
    }
  } else {
    // Open the gallery for the first time
    openGalleryItemIndexes(galleryIndex, itemIndex);
  }
}

// ============================================================================
// Scroll to Element
// ============================================================================

function getViewportHeight() {
  debugLog('Scroll', 'getViewportHeight');

  return window.innerHeight || document.documentElement.clientHeight;
}

function getViewportWidth() {
  debugLog('Scroll', 'getViewportWidth');

  return window.innerWidth || document.documentElement.clientWidth;
}

function getWindowScrollPosition() {
  debugLog('Scroll', 'getWindowScrollPosition');

  return {
    x: window.scrollX || window.pageXOffset,
    y: window.scrollY || window.pageYOffset
  };
}

function getCurrentItem() {
  debugLog('Scroll', 'getCurrentItem');

  if (!isValidLightbox()) {
    return null;
  }

  const currSlide = lightbox.pswp.currSlide;
  
  if (!currSlide || !currSlide.data) return null;
  
  // Scroll the element into view if it is off-screen
  return currSlide.data.element;
}

function getScrollElement() {
  debugLog('Scroll', 'getScrollElement');

  let currentItem = getCurrentItem();

  if (!currentItem) {
    return null;
  }
  
  // Check if the element's parent is a figure with a figcaption child
  if (currentItem.parentNode.tagName.toLowerCase() === 'figure' && currentItem.parentNode.querySelector('figcaption')) {
    currentItem = currentItem.parentNode;
  }

  return currentItem;
}

// function getViewportBoundaries() {
//   debugLog('Scroll', 'getViewportBoundaries');

//   const viewportHeight = getViewportHeight();
//   const viewportWidth = getViewportWidth();

//   return {
//     top: ScrollMargin,
//     bottom: viewportHeight - scrollMarginBottom,
//     left: 0,
//     right: viewportWidth
//   };
// }

// function getScrollElementPositionY() {
//   debugLog('Scroll', 'getScrollElementPositionY');

//   const elementRect = scrollElement.getBoundingClientRect();
//   const viewportScrollPosition = getWindowScrollPosition().y;

//   let { top, bottom } = getViewportBoundaries();

//   // Determine if we need to scroll up or down to show the element
//   const needsToScrollUp = elementRect.top < top;
//   const needsToScrollDown = elementRect.bottom > bottom;

//   // Adjust the top margin if we need to scroll up and the navbar is visible
//   if (needsToScrollUp) {
//     top += scrollMarginTopElementMargin;
//   }

//   debugLog('Scroll', 'getScrollElementPositionY, top:', top, 'needsToScrollUp:', needsToScrollUp, 'needsToScrollDown:', needsToScrollDown);

//   const boundaryHeight = bottom - top;

//   // Calculate the target scroll position
//   let targetScrollPosition;

//   // If the element is smaller than the viewport bounds
//   if (elementRect.height <= boundaryHeight) {
//     if (needsToScrollUp) {
//       // Align the top of the element with the top boundary
//       targetScrollPosition = viewportScrollPosition + elementRect.top - top;
//     } else if (needsToScrollDown) {
//       // Align the bottom of the element with the bottom boundary
//       targetScrollPosition = viewportScrollPosition + elementRect.bottom - bottom;
//     }
//   } else {
//     // If the element is larger than the viewport bounds, align the top of the element with the top boundary
//     targetScrollPosition = viewportScrollPosition + elementRect.top - top;
//   }

//   // Fallback to the current scroll position if no scrolling is needed
//   return targetScrollPosition !== undefined ? targetScrollPosition : viewportScrollPosition;
// }

function getScrollElementPositionY() {
  debugLog('Scroll', 'getScrollElementPositionY');

  const elementRect = scrollElement.getBoundingClientRect();
  const viewportScrollPosition = getWindowScrollPosition().y;
  const viewportHeight = getViewportHeight();

  // Calculate the target scroll position to center the element vertically
  let targetScrollPosition = viewportScrollPosition;

  if (elementRect.top < 0 || elementRect.bottom > viewportHeight) {
    // Center the element vertically in the viewport
    targetScrollPosition = viewportScrollPosition + elementRect.top - (viewportHeight / 2 - elementRect.height / 2);
  }

  debugLog('Scroll', 'getScrollElementPositionY, targetScrollPosition:', targetScrollPosition);

  return targetScrollPosition;
}

function scrollToScrollElement(duration) {
  debugLog('Scroll', 'scrollToScrollElement, duration: ', duration);

  const startPosition = getWindowScrollPosition().y;
  const targetPosition = getScrollElementPositionY();
  
  debugLog('Scroll', 'scrollToScrollElement, startPosition: ', startPosition);
  debugLog('Scroll', 'scrollToScrollElement, targetPosition: ', targetPosition);

  if (startPosition == targetPosition) {
    return;
  }

  if (duration === 0) {
    window.scrollTo(0, targetPosition); // Scroll immediately
    return;
  }

  if (typeof duration !== 'number' || duration < 0) {
    duration = lightbox.pswp.options.showAnimationDuration;
  }

  const distance = targetPosition - startPosition;
  let startTime = null;

  function animation(currentTime) {
    if (startTime === null) startTime = currentTime;
    
    const timeElapsed = currentTime - startTime;
    const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);

    window.scrollTo(0, run);

    if (timeElapsed < duration) {
      scrollAnimationFrame = requestAnimationFrame(animation);
    }
  }

  function easeInOutQuad(t, b, c, d) {
    if (d === 0) return b + c; // Immediately go to the target position if duration is 0
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  }

  scrollAnimationFrame = requestAnimationFrame(animation);
}

function jumpToScrollElement() {
  debugLog('Scroll', 'jumpToScrollElement');
  
  if (scrollAnimationFrame) {
    cancelAnimationFrame(scrollAnimationFrame);
    scrollToScrollElement(0);
  }
}

// Use this function to scroll the element into view if it is off-screen
function processScrollElement() {
  scrollElement = getScrollElement();

  debugLog('Scroll', 'processScrollElement, scrollElement: ', scrollElement);

  scrollToScrollElement();
}

// ============================================================================
// Share
// ============================================================================

function updateShareMenuPos() {
  debugLog('Share', 'updateShareMenuPos');

  const button = document.getElementById('pswp__button--share');
  const menu = document.getElementById('pswp__menu--share');
  
  if (!(button && menu)) {
    return;
  }

  // FloatingUIDOM.computePosition(button, menu, {
  //   placement: 'bottom',
  //   middleware: [FloatingUIDOM.offset(16), FloatingUIDOM.shift()],
  // }).then(({ x, y }) => {
  //   Object.assign(menu.style, {
  //     left: `${x}px`,
  //     top: `${y}px`,
  //   });
  // });

  // Get the margin values
  // const marginTop = parseInt(window.getComputedStyle(menu).marginTop);
  // const marginBottom = parseInt(window.getComputedStyle(menu).marginBottom);
  const marginLeft = parseInt(window.getComputedStyle(menu).marginLeft);
  const marginRight = parseInt(window.getComputedStyle(menu).marginRight);

  // Get the button's bounding rectangle
  const buttonRect = button.getBoundingClientRect();

  // Calculate the menu's position
  let menuX = buttonRect.left + (buttonRect.width / 2) - (menu.offsetWidth / 2);
  const menuY = buttonRect.bottom;

  // Ensure the menu stays within the viewport
  const viewportWidth = window.innerWidth;
  if (menuX < marginLeft) {
    menuX = marginLeft;
  } else if (menuX + menu.offsetWidth + marginRight > viewportWidth) {
    menuX = viewportWidth - menu.offsetWidth - marginRight;
  }

  // Set the menu's position
  menu.style.left = `${menuX - marginLeft}px`;
  menu.style.top = `${menuY}px`;
}

function toggleMenuShare(enable = true) {
  debugLog('Share', 'toggleMenuShare, enable:', enable);

  const button = document.getElementById('pswp__button--share');
  const menu = document.getElementById('pswp__menu--share');
  
  if (!(button && menu)) {
    return;
  }

  if (enable) {
    if (!button.classList.contains('pswp__button--open')) {
      button.classList.add('pswp__button--open');
    }
    if (!menu.classList.contains('pswp__menu--open')) {
      menu.classList.add('pswp__menu--open');
      updateShareMenuPos();
    }
  } else {
    if (button.classList.contains('pswp__button--open')) {
      button.classList.remove('pswp__button--open');
    }
    if (menu.classList.contains('pswp__menu--open')) {
      menu.classList.remove('pswp__menu--open');
    }
  }
}

// Function to toggle share menu
function toggleShare(event) {
  debugLog('Share', 'toggleShare');

  event.stopPropagation();
  // const button = event.currentTarget;
  const menu = document.getElementById('pswp__menu--share');

  toggleMenuShare(!menu.classList.contains('pswp__menu--open'));
      
  updateShareMenuPos();
}

// Declare a variable to hold the timer
let copyUrlTimer = null;
const copyUrlText = '<span class="icon-module_photoswipe-copy"></span> Copy URL';

// Function to copy URL to clipboard
function copyUrl(event) {
  debugLog('Share', 'copyUrl, copyUrlTimer:', copyUrlTimer);

  event.preventDefault();
  const button = event.currentTarget;

  navigator.clipboard.writeText(window.location.href).then(() => {
    // Clear any existing timer
    if (copyUrlTimer) {
      clearTimeout(copyUrlTimer);
    } else {
      button.innerHTML = '<span class="icon-module_photoswipe-copy"></span> Copied!';
    }

    // Set a new timer
    copyUrlTimer = setTimeout(() => {
      button.innerHTML = copyUrlText;
      copyUrlTimer = null; // Reset the timer variable
    }, 3000); // Change back to original text after 3 seconds
  });
}

// Function to check if the item is an image and toggle the visibility of the download button
function toggleDownloadButton(pswp) {
  debugLog('Share', 'toggleDownloadButton, pswp.currSlide.data:', pswp.currSlide.data);

  const downloadButton = document.getElementById('download-image');

  const imageExtensions = ['.jpeg', '.jpg', '.gif', '.png', '.bmp', '.webp', '.svg'];
  const isImage = imageExtensions.some(ext => pswp.currSlide.data.src.toLowerCase().endsWith(ext));

  const prevDisplay = downloadButton.style.display;

  if (isImage) {
    downloadButton.style.display = 'block';
  } else {
    downloadButton.style.display = 'none';
  }

  if (downloadButton.style.display !== prevDisplay) {
    updateShareMenuPos();
  }
}

// Function to download image
function downloadImage(event) {
  debugLog('Share', 'downloadImage');

  event.preventDefault();
  const url = pswp.currSlide.data.src;

  // Fetch the image blob and download it
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = url.split('/').pop(); // Extract the filename from the URL
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(objectUrl); // Clean up the URL object
      document.body.removeChild(a);
    })
    .catch(err => console.error('Failed to download image', err));
}

lightbox.on('uiRegister', function() {
  debugLog('Share', 'uiRegister pswp__button--share');

  lightbox.pswp.ui.registerElement({
    name: 'share-button',
    order: 8,
    isButton: true,
    tagName: 'button',
    html: `<span class="icon-module_photoswipe-share"></span>`,
    title: 'Share',
    className: 'pswp__button--share',
    onClick: toggleShare,

    onInit: (el, pswp) => {
      debugLog('Share', 'pswp__button--share onInit');
    
      el.id = 'pswp__button--share';
    }
  });
});

lightbox.on('uiRegister', function() {
  debugLog('Share', 'uiRegister pswp__menu--share');

  lightbox.pswp.ui.registerElement({
    name: 'share-menu',
    order: 7,
    isButton: false,
    tagName: 'div',
    html: `
      <a id="copy-url"       class="pswp__button" href="#" target="_blank" rel="noopener" download="">${copyUrlText}</a>
      <a id="download-image" class="pswp__button" href="#" target="_blank" rel="noopener" download=""><span class="icon-module_photoswipe-download"></span> Download Image</a>
    `,
    className: 'pswp__menu pswp__menu--share', 

    onInit: (el, pswp) => {
      el.id = 'pswp__menu--share';

      pswp.on('change', () => {
        debugLog('Share', 'pswp__menu--share change');
      
        toggleDownloadButton(pswp);
      });
    }
  });
});

// ============================================================================
// Fullscreen
// ============================================================================

// Function to check if we are in fullscreen mode
function isFullscreen() {
  debugLog('Fullscreen', 'debugLog_Fullscreen');

  return !!(
    // alternative standard method:
    document.fullscreenElement ||
    // current working methods:
    document.mozFullScreenElement ||
    document.webkitFullscreenElement ||
    document.msFullscreenElement
  );
}

// Function to check if fullscreen is supported
function isFullscreenSupported() {
  debugLog('Fullscreen', 'isFullscreenSupported');

  return !!(
    document.fullscreenEnabled ||
    document.mozFullScreenEnabled ||
    document.webkitFullscreenEnabled ||
    document.msFullscreenEnabled
  );
}

// Function to update fullscreen button content
function updateFullscreenButton(isFullscreen) {
  debugLog('Fullscreen', 'updateFullscreenButton, isFullscreen:', isFullscreen);

  const button = document.querySelector('.pswp__button--fs');
  if (button) {
    if (isFullscreen) {
      button.innerHTML = '<span class="icon-module_photoswipe-fullscreen-disable"></span>';
    } else {
      button.innerHTML = '<span class="icon-module_photoswipe-fullscreen-enable"></span>';
    }
  }
}

// Function to request fullscreen mode
function enterFullscreen() {
  debugLog('Fullscreen', 'enterFullscreen');

  const elem = document.documentElement; // You can also target a specific element if needed
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) { // Firefox
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) { // Chrome, Safari and Opera
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { // IE/Edge
    elem.msRequestFullscreen();
  }
  updateFullscreenButton(true);
}

// Function to exit fullscreen mode
function exitFullscreen() {
  debugLog('Fullscreen', 'exitFullscreen');

  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) { // Firefox
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) { // IE/Edge
    document.msExitFullscreen();
  }
  updateFullscreenButton(false);
}

// Function to toggle fullscreen mode
function toggleFullscreen() {
  debugLog('Fullscreen', 'toggleFullscreen');

  if (!isFullscreen()) {
    enterFullscreen();
  } else {
    exitFullscreen();
  }
}

if (isFullscreenSupported()) {
  lightbox.on('uiRegister', function() {
    debugLog('Fullscreen', 'uiRegister pswp__button--fs');
  
    lightbox.pswp.ui.registerElement({
      name: 'fullscreen-button',
      order: 8,
      isButton: true,
      tagName: 'button',
      html: '<span class="icon-module_photoswipe-fullscreen-enable"></span>',
      title: 'Fullscreen',
      className: 'pswp__button--fs',
      onClick: toggleFullscreen
    });
  });
}

// ============================================================================
// Zoom Indicator
// ============================================================================

// lightbox.on('uiRegister', function() {
//   lightbox.pswp.ui.registerElement({
//     name: 'zoom-level-indicator',
//     order: 9,
//     onInit: (el, pswp) => {
//       pswp.on('zoomPanUpdate', (e) => {
//         if (e.slide === pswp.currSlide) {
//           el.innerHTML = '<span class="label">Zoom</span><span class="value">' + Math.ceil(pswp.currSlide.currZoomLevel * 100) + '%</span>';
//         }
//       });
//     }
//   });
// });

// ============================================================================
// Caption
// ============================================================================

lightbox.on('uiRegister', function() {
  debugLog('Caption', 'uiRegister custom-caption');

  lightbox.pswp.ui.registerElement({
    name: 'custom-caption',
    order: 9,
    isButton: false,
    appendTo: 'root',
    html: 'Caption text',
    onInit: (el, pswp) => {
      lightbox.pswp.on('change', () => {
        debugLog('Caption', 'custom-caption change');
      
        const currSlideElement = lightbox.pswp.currSlide.data.element;
        let captionHTML = '';
        if (currSlideElement) {
          const parentElement = currSlideElement.parentElement;
          let captionElement = null;

          if (parentElement && parentElement.tagName.toLowerCase() === 'figure') {
            captionElement = parentElement.querySelector('figcaption');
          }

          if (captionElement) {
            // get caption from figcaption element
            captionHTML = captionElement.innerHTML;
          } else {
            // get caption from alt attribute
            captionHTML = currSlideElement.querySelector('img').getAttribute('alt');
          }
        }
        if (captionHTML) {
          el.innerHTML = captionHTML;
          el.style.display = 'block';
        } else {
          el.innerHTML = '';
          el.style.display = 'none';
        }
      });
    }
  });
});

// ============================================================================
// Idle Fade - Desktop
// ============================================================================

let mouseTimer;
const fadeDuration = 2000; // milliseconds

let mouseOverUICount = 0;

function isIdleAllowed() {
  return isOpenFully && !mouseOverUICount;
}

// Function to handle mouse movements and interactions
function handleInteraction() {
  // console.log('Interaction detected. Showing UI...');

  showUI();

  if (!isIdleAllowed()) {
    return;
  }

  mouseTimer = setTimeout(hideUI, fadeDuration);
}

// Function to show the UI elements
function showUI() {
  // console.log('Showing UI elements...');

  clearTimeout(mouseTimer);

  if (!pswpElement) {
    return;
  }

  pswpElement.classList.remove('idle-fade');
}

// Function to hide the UI elements
function hideUI() {
  // console.log('Hiding UI elements...');

  if (!pswpElement) {
    return;
  }

  pswpElement.classList.add('idle-fade');
  toggleMenuShare(false);
}

function isDesktop() {
  return !('ontouchstart' in window || navigator.maxTouchPoints);
}
 
// function isDesktop() {
//   const hasTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
//   const userAgent = navigator.userAgent || navigator.vendor || window.opera;
//   const isAndroid = /android/i.test(userAgent);
//   const isMobile = /mobile/i.test(userAgent);

//   // Return true if the device is not touch-capable or it's not an Android/mobile device
//   return !hasTouch && !isAndroid && !isMobile;
// }

// Add event listeners for user interactions
if (isDesktop()) {
  document.addEventListener('mousemove', handleInteraction);
  document.addEventListener('click', handleInteraction);

  // purposefully avoiding keyboard to allow ui-less presentations.

  // document.addEventListener('keydown', handleInteraction);
  // document.addEventListener('keyup', handleInteraction);

  // purposefully avoiding touch as touch is already handled by PhotoSwipe.

  // document.addEventListener('touchstart', handleInteraction);
  // document.addEventListener('touchmove', handleInteraction);
  // document.addEventListener('touchend', handleInteraction);
}

// ============================================================================
// Google Map
// ============================================================================

// parse data-google-map-url attribute
lightbox.addFilter('itemData', (itemData, index) => {
  
  const el = itemData.element;

  if (!el) {
    return;
  }

  const googleMapUrl = el.dataset.googleMapUrl;
  if (googleMapUrl) {
    itemData.googleMapUrl = googleMapUrl;
  }
  return itemData;
});

// ============================================================================
// Event - Content
// ============================================================================

// lightbox.on('contentInit', ({ content }) => {
//   debugLog('Content', 'contentInit', content);
// });

// content start to lazy-load
// can be default prevented
// lightbox.on('contentLazyLoad', ({ content }) => {
//   debugLog('Content', 'contentLazyLoad', content);
// });

// content starts to load 
// can be default prevented
// assign elements to `content.element`
// lightbox.on('contentLoad', ({ content, isLazy }) => {
//   debugLog('Content', 'contentLoad', content, isLazy);
// });

lightbox.on('contentLoad', (e) => {
  debugLog('Content', 'contentLoad', e);

  const { content } = e;
  if (content.type === 'google-map') {
    // prevent the default behavior
    e.preventDefault();

    // Create a container for iframe
    // and assign it to the `content.element` property
    content.element = document.createElement('div');
    content.element.className = 'pswp__google-map-container';

    const iframe = document.createElement('iframe');
    iframe.setAttribute('allowfullscreen', '');
    iframe.src = content.data.googleMapUrl;
    content.element.appendChild(iframe);
  }
});

// similar to the previous one, but triggers only for image content
// can be default prevented
// lightbox.on('contentLoadImage', ({ content, isLazy }) => {
//   debugLog('Content', 'contentLoadImage', content, isLazy);
// });

// lightbox.on('loadComplete', ({ content, slide }) => {
//   debugLog('Content', 'loadComplete', content);
// });

// content will be resized
// can be default prevented
// lightbox.on('contentResize', ({ content, width, height }) => {
//   debugLog('Content', 'contentResize', content, width, height);
// });

// content.element is image
// lightbox.on('imageSizeChange', ({ content, width, height, slide }) => {
//   debugLog('Content', 'imageSizeChange', content, width, height, slide, slide.index);
// });

// content is added to dom
// can be default prevented
// content.slide.container.appendChild(content.element);
// lightbox.on('contentAppend', ({ content }) => {
//   debugLog('Content', 'contentAppend', content);
// });

// content becomes active (the current slide)
// can be default prevented
// lightbox.on('contentActivate', ({ content }) => {
//   debugLog('Content', 'contentActivate', content);
// });

// ============================================================================
// Blur
// ============================================================================

let supportsBackdropFilter = null;
let elementBlur = null;

function getSupportsBackdropFilter() {
  debugLog('Blur', 'getSupportsBackdropFilter');

  const element = document.createElement('div');
  element.style.cssText = 'backdrop-filter: blur(2px); -webkit-backdrop-filter: blur(2px);';

  document.body.appendChild(element);
  const computedStyle = window.getComputedStyle(element);
  const isSupported = !!computedStyle.backdropFilter ? 'backdropFilter' : !!computedStyle.webkitBackdropFilter ? 'webkitBackdropFilter' : null;

  document.body.removeChild(element);

  return isSupported;
}

function animateBlur(element, start, end, duration) {
  debugLog('Blur', 'animateBlur , element:', element, ', start:', start, ', end:', end, ', duration:', duration);

  if (duration === 0) {
    element.style[supportsBackdropFilter] = `blur(${end}px)`;
    return;
  }

  const startTime = performance.now();

  function animate(currentTime) {
    const elapsedTime = currentTime - startTime;
    const progress = Math.min(elapsedTime / duration, 1);
    const currentBlur = start + (end - start) * progress;

    element.style[supportsBackdropFilter] = `blur(${currentBlur}px)`;

    if (progress < 1) {
      requestAnimationFrame(animate);
    }
  }

  requestAnimationFrame(animate);
}

function initBlur() {
  debugLog('Blur', 'initBlur');

  if (supportsBackdropFilter === null) {
    supportsBackdropFilter = getSupportsBackdropFilter();
  }

  if (!supportsBackdropFilter) {
    return;
  }

  // Step 1: Create a new element
  if (elementBlur) {
    return;
  }

  elementBlur = document.createElement('div');

  // Step 2: Set its class
  elementBlur.classList.add('pswp__bg_blur');

  // Step 3: Append it to the desired parent element
  // Assuming you want to append it to the body
  document.body.appendChild(elementBlur);

  // Animate blur in
  animateBlur(elementBlur, 0, 10, getAnimationDuration()); // Adjust end blur value as needed
}

function hideBlur() {
  debugLog('Blur', 'hideBlur');

  if (elementBlur) {
    // Animate blur out
    animateBlur(elementBlur, 10, 0, lightbox.pswp.options.hideAnimationDuration); // Adjust end blur value as needed
  }
}

function destroyBlur() {
  debugLog('Blur', 'destroyBlur');

  if (elementBlur) {
    elementBlur.parentNode.removeChild(elementBlur);
    elementBlur = null;
  }
}

// ============================================================================
// Event - Open
// ============================================================================

function shouldInitialLoadSkipAnimation() {
  debugLog('Event', 'shouldInitialLoadSkipAnimation');

  if (!initialLoad) {
    return false;
  }

  const hashData = photoswipeParseHash();
  return hashData.item || hashData.gallery;
}

function getAnimationDuration() {
  return shouldInitialLoadSkipAnimation() ? 0 : 333;
}

// photoswipe starts to open
lightbox.on('beforeOpen', () => {
  debugLog('Event', 'beforeOpen');

  lightbox.pswp.options.showAnimationDuration = getAnimationDuration();
});

// photoswipe keeps opening
// you may modify initial index or basic DOM structure
lightbox.on('firstUpdate', function() {
  debugLog('Event', 'firstUpdate');

  fullscreenOnOpen = isFullscreen();
  updateFullscreenButton(fullscreenOnOpen);

  initBlur();
});

// photoswipe measures size of various elements
// if you need to read getBoundingClientRect of something - do it here
// lightbox.on('initialLayout', () => {
//   debugLog('Event', 'initialLayout');
// });

// triggers when slide is switched, and at initialization
lightbox.on('change', () => {
  debugLog('Event', 'change');
  
  // Update URL when slide changes
  const currSlide = lightbox.pswp.currSlide;
  
  if (!currSlide || !currSlide.data) return;
  
  const galleryElements = document.querySelectorAll(gallerySelector);
  let gallery = 0;
  for (let i = 0; i < galleryElements.length; i++) {
    if (galleryElements[i].contains(currSlide.data.element)) {
      gallery = i + 1;
      break;
    }
  }
  const item = currSlide.index + 1;
  updateURL(gallery, item);

  processScrollElement();
});

// photoswipe fully initialized and opening transition is running (if available)
// lightbox.on('afterInit', () => {
//   debugLog('Event', 'afterInit');
// });

lightbox.on('bindEvents', () => {
  debugLog('Event', 'bindEvents');
  // photoswipe binds DOM events (such as pointer events, wheel, etc)

  // Event listeners for .pswp__button elements to track mouseover state
  document.querySelectorAll('.pswp__button, .pswp__counter, .pswp__zoom-level-indicator, .pswp__custom-caption, .pswp__menu').forEach(element => {
    element.addEventListener('mouseenter', () => {
      mouseOverUICount += 1;
      // console.log(`Mouse entered: ${element.className}, button count: ${mouseOverUICount}`);
    });
    element.addEventListener('mouseleave', () => {
      mouseOverUICount = Math.max(mouseOverUICount - 1, 0);
      // console.log(`Mouse left: ${element.className}, button count: ${mouseOverUICount}`);
    });
  });

  // mobile bg touch close
  // Seems the following doesnt work due to a middle layer intercepting the touch events for use of the double tap zoom feature:
  // document.querySelector('.pswp__bg').addEventListener('touchstart', function() {
  //   lightbox.pswp.close();
  // });
  
  // share
  document.getElementById('copy-url').addEventListener('click', copyUrl);
  document.getElementById('download-image').addEventListener('click', downloadImage);
});

lightbox.on('openingAnimationStart', () => {
  debugLog('Event', 'closingAnimationStart');

  isOpenFully = false;
  isSwitchingGalleries = false;

  pswpElement = document.querySelector('.pswp');
  if (pswpElement) {
    pswpElement.classList.add('pswp--animation-open');
  }
});

lightbox.on('openingAnimationEnd', () => {
  debugLog('Event', 'closingAnimationEnd');

  if (pswpElement) {
    pswpElement.classList.remove('pswp--animation-open');
  }

  initialLoad = false;
  isOpenFully = true;

  if (isDesktop()) {
    handleInteraction();
  }
});

// ============================================================================
// Event - Close
// ============================================================================

// PhotoSwipe starts to close, unbind most events here
lightbox.on('close', () => {
  debugLog('Event', 'close');
  
  jumpToScrollElement();
});

lightbox.on('closingAnimationStart', () => {
  debugLog('Event', 'closingAnimationStart');

  isOpenFully = false;
  
  jumpToScrollElement();

  if (!isSwitchingGalleries) {
    updateURLWithoutGalleryItem();
  }

  if (!fullscreenOnOpen && isFullscreen()) {
    exitFullscreen();
  }

  showUI();

  if (pswpElement) {
    pswpElement.classList.add('pswp--animation-close');
  }

  hideBlur();
});

// lightbox.on('closingAnimationEnd', () => {
//   debugLog('Event', 'closingAnimationEnd');
// });

// content is removed from DOM
// can be default prevented
// lightbox.on('contentRemove', ({ content }) => {
//   debugLog('Content', 'contentRemove', content);
// });

// content will be destroyed
// can be default prevented
// lightbox.on('contentDestroy', ({ content }) => {
//   debugLog('Content', 'contentDestroy', content);
// });

// PhotoSwipe is fully closed, destroy everything
lightbox.on('destroy', () => {
  debugLog('Event', 'destroy');

  jumpToScrollElement();

  if (isSwitchingGalleries) {
    setTimeout(() => {
      openGalleryItemIndexes(newSwitchingGalleryIndex, newSwitchingItemIndex);
    }, 1);
  }

  destroyBlur();
});

// ============================================================================
// Pointer and gesture events
// ============================================================================

// lightbox.on('pointerDown', (e) => {
//   debugLog('PointerGesture', 'pointerDown', e.originalEvent);
// });

// lightbox.on('pointerMove', (e) => {
//   debugLog('PointerGesture', 'pointerMove', e.originalEvent);
// });

// lightbox.on('pointerUp', (e) => {
//   debugLog('PointerGesture', 'pointerUp', e.originalEvent);
// });

// lightbox.on('pinchClose', (e) => {
//   // triggered when using pinch to close gesture
//   // can be default prevented
//   debugLog('PointerGesture', 'pinchClose', e.bgOpacity);
// });

// lightbox.on('verticalDrag', (e) => {
//   // triggered when using vertical drag to close gesture
//   // can be default prevented
//   debugLog('PointerGesture', 'verticalDrag', e.panY);
// });

// ============================================================================
// Init
// ============================================================================

lightbox.init();

// ============================================================================
// URL
// ============================================================================

openGalleryFromHash();

// Function to handle hash changes
function onHashChange() {
  debugLog('URL', 'onHashChange, window.location.hash:', window.location.hash);

  openGalleryFromHash();
}

// Add an event listener for the hashchange event
window.addEventListener('hashchange', onHashChange, false);

// Add an event listener for the popstate event to handle browser back button
window.addEventListener('popstate', (event) => {
  debugLog('URL', 'popstate, event.state:', event.state);

  if (!event.state) {
    return;
  }

  if (isValidLightbox()) {
    lightbox.pswp.close();
  }
}, false);

// ============================================================================
// Control
// ============================================================================

function PSWP_Open(gallery, item) {
  debugLog('URL', 'PSWP_Open, gallery:', gallery, ', item:', item);

  updateURL(gallery, item);

  // Open the gallery based on the new hash
  openGalleryFromHash();
}

// ============================================================================
// Document & Window
// ============================================================================

// Function to reposition the menu on window resize
function onWindowResize() {
  debugLog('EventDocumentWindow', 'onWindowResize');

  if (!isValidLightbox()) {
    return;
  }

  // share
  updateShareMenuPos();
}

// Bind the resize event to the window
window.addEventListener('resize', onWindowResize);
